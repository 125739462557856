@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,700;1,400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body{
  font-family: Inter;
}
.special-text {
  font-family: 'Libre Baskerville', serif !important;
  font-style: italic;
}


.globe-icon {
  font-size: 20px; 
}

@media (max-width: 768px) {
  /* Limit the maximum width of images */
  img {
    max-width: 100%;
    height: auto; 
    margin: 0 auto; 
  }
}